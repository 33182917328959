/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
//import useFirebaseConfig from "gatsby-theme-firebase/src/hooks/useFirebaseConfig";
import FormState from "gatsby-theme-firebase/src/containers/FormState";
import { auth } from "gatsby-theme-firebase/src/firebase";
import Form from "gatsby-theme-firebase/src/components/FormBase";
import Input from "gatsby-theme-firebase/src/components/Input";
import Button from "gatsby-theme-firebase/src/components/Button";

const LoginForm: React.FunctionComponent<{
  onSuccess?: (user?: firebase.auth.UserCredential) => void;
}> = ({ onSuccess = () => {}, ...restProps }) => {
  const { setFormType, setErrorMessage } = FormState.useContainer();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  return (
    <Form
      {...restProps}
      onSubmit={async event => {
        event.preventDefault();
        try {
          const user = await auth.signInWithEmailAndPassword(email, password);
          if(user.user.emailVerified) {
            onSuccess(user);
          }
          else {
            setPassword("");
            throw new Error("Please verify your email before logging in.")
          }
        } catch (error) {
          setErrorMessage(error.message);
        }
      }}
    >
      <Input
        label="Email"
        type="text"
        placeholder="email@example.com"
        value={email}
        onChange={event => {
          setEmail(event.target.value);
        }}
      />
      <Input
        label="Password"
        type="password"
        value={password}
        sx={{ mb: 3 }}
        onChange={event => {
          setPassword(event.target.value);
        }}
      />
      <Button type="submit" sx={{ width: "100%", mb: 1 }}>
        Log in
      </Button>

      <hr />

      <Button
        sx={{
          width: "100%",
          bg: "white",
          color: "primary",
          p: 0,
          fontSize: 0,
          fontWeight: "body",
        }}
        onClick={event => {
          event.preventDefault();
          setFormType("passwordReset");
        }}
      >
        Forgot your password?
      </Button>
    </Form>
  );
};

export default LoginForm;